var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        btnLoading: _vm.btnLoading,
        show: _vm.open,
        isForm: _vm.isForm,
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        {},
        [
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.curRow.materialOrder))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "分类" } },
            [
              _vm._v(
                _vm._s(_vm.curRow.materialType && _vm.curRow.materialType.name)
              ),
            ]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "名称" } },
            [_vm._v(_vm._s(_vm.curRow.name))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用部门" } },
            _vm._l(_vm.curRow.departs, function (one) {
              return _c("a-tag", { key: one.id }, [
                _vm._v("\n        " + _vm._s(one.name) + "\n      "),
              ])
            }),
            1
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用职位" } },
            _vm._l(_vm.curRow.positions, function (one) {
              return _c("a-tag", { key: one.positionId }, [
                _vm._v("\n        " + _vm._s(one.name) + "\n      "),
              ])
            }),
            1
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "描述" } },
            [_vm._v(_vm._s(_vm.curRow.description))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "stageName" } },
                [
                  _c("a-input-number", {
                    attrs: { min: 0, placeholder: "请输入序号" },
                    model: {
                      value: _vm.formData.materialOrder,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "materialOrder", $$v)
                      },
                      expression: "formData.materialOrder",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "分类", prop: "stageName" } },
                [
                  _c(
                    "a-tree-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "show-search": "",
                        "dropdown-style": {
                          maxHeight: "400px",
                          overflow: "auto",
                        },
                        placeholder: "请选择分类",
                        "allow-clear": "",
                        "tree-default-expand-all": "",
                        searchPlaceholder: "查找",
                      },
                      on: { change: _vm.treeChange },
                      model: {
                        value: _vm.materialType,
                        callback: function ($$v) {
                          _vm.materialType = $$v
                        },
                        expression: "materialType",
                      },
                    },
                    [
                      _vm._l(_vm.materialTypeList, function (item) {
                        return _c(
                          "a-tree-select-node",
                          { key: item.id, attrs: { value: item.name } },
                          [
                            _c("template", { slot: "title" }, [
                              _c("div", [
                                !item.isEdit
                                  ? _c(
                                      "div",
                                      { staticClass: "material-tree-item" },
                                      [
                                        _c("div", [_vm._v(_vm._s(item.name))]),
                                        _c("div", [
                                          _c(
                                            "a",
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "delete" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.materialTypeDele(
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px",
                                              },
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "edit" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.materialTypeEdit(
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "material-tree-item" },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("a-input", {
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                },
                                              },
                                              model: {
                                                value: item.name,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "name", $$v)
                                                },
                                                expression: "item.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "line-height": "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "item-cancel",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.materialCancel(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      取消\n                    "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticStyle: {
                                                  "margin-left": "15px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.filish(item)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      完成\n                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                              ]),
                            ]),
                          ],
                          2
                        )
                      }),
                      _c(
                        "a-tree-select-node",
                        {
                          key: "add_material",
                          attrs: { value: "add_material" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c("div", [
                              !_vm.addData.isEdit
                                ? _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.materialTypeEdit(
                                            _vm.addData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "a",
                                          [
                                            _c("a-icon", {
                                              staticStyle: {
                                                display: "inline-block",
                                              },
                                              attrs: { type: "plus" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c("div"),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "material-tree-item" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("a-input", {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                            },
                                            model: {
                                              value: _vm.addData.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.addData,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "addData.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "line-height": "32px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "item-cancel",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.materialCancel(
                                                    _vm.addData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      取消\n                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.filish(_vm.addData)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      完成\n                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择部门", prop: "selectedDepart" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      treeDefaultExpandAll: "",
                      "tree-checkable": "",
                      "tree-data": _vm.departList,
                      "search-placeholder": "选择部门",
                      placeholder: "请选择适用部门",
                    },
                    on: { change: _vm.changeDepartList },
                    model: {
                      value: _vm.selectedDeparts,
                      callback: function ($$v) {
                        _vm.selectedDeparts = $$v
                      },
                      expression: "selectedDeparts",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择职位", prop: "selectedPositions" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "tree-checkable": "",
                      "tree-data": _vm.getTreePositionList,
                      "search-placeholder": "选择职位",
                      placeholder: "请选择适用职位",
                    },
                    on: { change: _vm.changePositionList },
                    model: {
                      value: _vm.selectedPositions,
                      callback: function ($$v) {
                        _vm.selectedPositions = $$v
                      },
                      expression: "selectedPositions",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "描述", prop: "stageName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入描述" },
                    model: {
                      value: _vm.formData.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "description", $$v)
                      },
                      expression: "formData.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }