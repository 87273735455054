<template>
  <drawer-layout
    :title="setTitle"
    :btnLoading="btnLoading"
    :show="open"
    :isForm="isForm"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <div class="">
      <info-item :label-width="labelWidth" label="序号">{{ curRow.materialOrder }}</info-item>
      <info-item :label-width="labelWidth" label="分类">{{
        curRow.materialType && curRow.materialType.name
      }}</info-item>
      <info-item :label-width="labelWidth" label="名称">{{ curRow.name }}</info-item>
      <info-item :label-width="labelWidth" label="适用部门">
        <a-tag v-for="one in curRow.departs" :key="one.id">
          {{ one.name }}
        </a-tag>
      </info-item>
      <info-item :label-width="labelWidth" label="适用职位">
        <a-tag v-for="one in curRow.positions" :key="one.positionId">
          {{ one.name }}
        </a-tag>
      </info-item>
      <info-item :label-width="labelWidth" label="描述">{{ curRow.description }}</info-item>
    </div>
    <div slot="form">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="序号" prop="stageName">
          <a-input-number :min="0" v-model="formData.materialOrder" placeholder="请输入序号"></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="分类" prop="stageName">
          <a-tree-select
            v-model="materialType"
            show-search
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            placeholder="请选择分类"
            allow-clear
            tree-default-expand-all
            @change="treeChange"
            searchPlaceholder="查找"
          >
            <a-tree-select-node v-for="item in materialTypeList" :key="item.id" :value="item.name">
              <template slot="title">
                <div>
                  <div v-if="!item.isEdit" class="material-tree-item">
                    <div>{{ item.name }}</div>
                    <div>
                      <a>
                        <a-icon @click.stop="materialTypeDele(item)" type="delete" />
                      </a>
                      <a style="margin-left:15px">
                        <a-icon @click.stop="materialTypeEdit(item)" type="edit" />
                      </a>
                    </div>
                  </div>
                  <div v-else class="material-tree-item">
                    <div><a-input @click.stop v-model="item.name"></a-input></div>
                    <div style="line-height:32px">
                      <span class="item-cancel" @click.stop="materialCancel(item)">
                        取消
                      </span>
                      <a style="margin-left:15px" @click.stop="filish(item)">
                        完成
                      </a>
                    </div>
                  </div>
                </div>
              </template>
            </a-tree-select-node>
            <a-tree-select-node key="add_material" value="add_material">
              <template slot="title">
                <div>
                  <div v-if="!addData.isEdit" @click.stop="materialTypeEdit(addData)">
                    <div>
                      <a>
                        <a-icon style="display:inline-block" type="plus" />
                      </a>
                    </div>
                    <div></div>
                  </div>
                  <div v-else class="material-tree-item">
                    <div><a-input @click.stop v-model="addData.name"></a-input></div>
                    <div style="line-height:32px">
                      <span class="item-cancel" @click.stop="materialCancel(addData)">
                        取消
                      </span>
                      <a style="margin-left:15px" @click.stop="filish(addData)">
                        完成
                      </a>
                    </div>
                  </div>
                </div>
              </template>
            </a-tree-select-node>
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="formData.name" placeholder="请输入名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="选择部门" prop="selectedDepart">
          <a-tree-select
            treeDefaultExpandAll
            v-model="selectedDeparts"
            style="width: 100%"
            tree-checkable
            :tree-data="departList"
            search-placeholder="选择部门"
            placeholder="请选择适用部门"
            @change="changeDepartList"
          />
        </a-form-model-item>
        <a-form-model-item label="选择职位" prop="selectedPositions">
          <a-tree-select
            v-model="selectedPositions"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreePositionList"
            search-placeholder="选择职位"
            placeholder="请选择适用职位"
            @change="changePositionList"
          />
        </a-form-model-item>
        <a-form-model-item label="描述" prop="stageName">
          <a-input v-model="formData.description" placeholder="请输入描述"></a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { deleteAction, getAction, postAction } from '../../../api/manage'

export default {
  mixins: [DrawerMixins],
  data() {
    return {
      rules: {},
      formData: {},
      addData: {
        name: '',
        isEdit: false
      },
      labelWidth: '80',
      materialType: undefined,
      materialTypeList: [],
      selectedDeparts: [],
      selectedPositions: [],
      layout: {
        labelCol: {
          style: 'width: 80px',
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      url: {
        add: '/material/category/edit',
        edit: '/material/category/edit'
      }
    }
  },
  methods: {
    async materialTypeDele(item) {
      const res = await deleteAction('/material/type/delete?ids=' + item.id)
      if (res.code != 200) {
        this.$message.error(res.msg)
      }else{
        this.getMaterialTypeLsit()
      }
    },
    saveSim() {
      const self = this
      this.setFormData()
      console.log('保存...')
      this.btnLoading = true
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          postAction(self.url.edit, self.formData).then(async res => {
            if (res.success) {
              if (self.inspectSimpleValue == 'inspectSimpleBlack' || self.inspectSimpleValue == 'inspectSimpleFilter') {
                const response = await postAction('/qualityInspection/blackWhiteSync', {})
                if (response.data.code != 0) {
                  self.$message.error(response.data)
                }
              }
              self.$message.success(res.message || res.msg ? '保存成功' : '')
              self.isSaved = true
              self.btnLoading = false
              self.closed()
            } else {
              self.btnLoading = false
              self.$message.error(res.message || res.msg)
            }
          })
        } else {
          self.btnLoading = false
        }
      })
    },
    async materialTypeEdit(item) {
      item.isEdit = true
      this.$forceUpdate()
    },
    materialCancel(item) {
      item.isEdit = false
      this.$forceUpdate()
    },
    changeDepartList(keys, t, { triggerNode }) {
      this.formData.departs = keys.map(item => {
        return {
          id: item
        }
      })
    },
    changePositionList(keys, t, { triggerNode }) {
      console.log('changePositionList', { keys, t, triggerNode })
      this.formData.positions = keys.map(item => {
        return {
          id: item
        }
      })
    },
    async filish(item) {
      const res = await postAction('/material/type/edit', item)
      if (res.code == 200) {
        this.addData.isEdit=false;
        this.addData.name='';
        this.getMaterialTypeLsit()
      } else {
        this.$message.error(res.msg)
      }
    },
    initForm() {
      this.formData = {}
      this.selectedDeparts = []
      this.selectedPositions = []
      this.addData = {
        name: '',
        isEdit: false
      }
      this.materialType = undefined
    },

    show() {
      console.log('显示...')
      this.getPosition()
      this.getDepartList()
      this.getMaterialTypeLsit()
      if (this.isEdit) {
        this.formData = this.curRow
        this.materialType = this.formData.materialType.name
        const { positions, departs } = this.formData
        this.selectedDeparts = departs.map(item => item.id)
        console.log({ positions: positions })
        this.selectedPositions = positions.map(item => {
          item.id
        })
      }
    },
    async getMaterialTypeLsit() {
      const res = await getAction('/material/type/list')
      if (res.code == 200) {
        this.materialTypeList = res.data
        this.materialTypeList.forEach(item => {
          item.isEdit = false
        })
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },
    startEdit() {
      if (this.isEdit) {
        this.formData = this.curRow
        this.materialType = this.formData.materialType.name
        const { departs, positions } = this.formData
        this.selectedDeparts = departs.map(item => item.id)
        console.log({ 'this.formData': this.formData })
        if (positions) {
          this.selectedPositions = positions.map(item => item.id)
          console.log({ 'show this.selectedPositions': this.selectedPositions })
        }
      }
    },

    setFormData() {},
    treeChange() {
      this.materialTypeList.forEach(item => {
        if (this.materialType == item.name) {
          this.formData.materialType = item
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.material-tree-item {
  display: flex;
  justify-content: space-between;
  .anticon {
    display: none;
  }
  &:hover {
    .anticon {
      display: inline-block;
    }
  }
  .item-cancel {
    &:hover {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
</style>
